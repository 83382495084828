import React from "react";
import moment from "moment";
import {TextField} from "@material-ui/core";
import {DatePicker as DatePickerMui, DateTimePicker as DateTimePickerMui, TimePicker as TimePickerMui} from "@material-ui/pickers";
import {withTheme} from "@material-ui/styles";

/**
 * Date time picker based on Material Pickers
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DateTimePicker extends React.PureComponent {

	/**
	 * Change handler.
	 *
	 * @param {Moment|null} value
	 * @return {void}
	 */
	handleChange = value => this.props.onChange(value, this.props.name);

	/**
	 * Change handler (native input).
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleChangeNative = e => {
		const value = e.target.value;
		if (value || this.props.clearable) {
			if ((!this.props.timeOnly && !this.props.dateOnly) || !this.props.native) {
				this.handleChange((value ? new moment(value) : null));
			}
			else this.handleChange((value || null));
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		let Component = null;

		if (this.props.native) {
			return this.renderNative();
		}
		else if (this.props.dateOnly) {
			Component = DatePickerMui;
		}
		else if (this.props.timeOnly) {
			Component = TimePickerMui;
		}
		else Component = DateTimePickerMui;

		return (
			<Component
				{...this.forwards}
				ampm={false}
				autoOk={true}
				format={this.format}
				onChange={this.handleChange}
				variant={this.props.pickerVariant}
				DialogProps={this.constructor.DialogProps}
				TextFieldComponent={this.renderTextField} />
		);

	}


	/**
	 * Render as a native control.
	 *
	 * @return {ReactNode}
	 */
	renderNative() {
		const styles = {
			border: `0.1rem solid`,
			borderColor: (!this.props.error ? this.props.theme.palette.grey["400"] : this.props.theme.palette.error.main),
			fontSize: "1.4rem",
			maxWidth: (!this.props.fullWidth ? "19rem" : undefined),
			padding: "0.75rem",
			userSelect: "all",
			...this.props.style
		};

		let type = "datetime-local";
		if (this.props.dateOnly) type = "date";
		else if (this.props.timeOnly) type = "time";

		let max = undefined;
		if (this.props.maxDate) {
			if (!this.props.dateOnly) max = `${(this.props.maxDate?.format?.("YYYY-MM-DD") || this.props.maxDate)}T23:59:59`;
			else max = (this.props.maxDate?.format?.("YYYY-MM-DD") || this.props.maxDate);
		}

		let min = undefined;
		if (this.props.minDate) {
			if (!this.props.dateOnly) min = `${(this.props.minDate?.format?.("YYYY-MM-DD") || this.props.minDate)}T00:00:00`;
			else min = (this.props.minDate?.format?.("YYYY-MM-DD") || this.props.minDate);
		}

		return (
			<input
				id={this.props.id}
				className={this.props.className}
				disabled={this.props.disabled}
				max={(this.props.max || max)}
				min={(this.props.min || min)}
				name={this.props.name}
				onChange={this.handleChangeNative}
				placeholder={this.props.placeholder}
				required={this.props.required}
				style={styles}
				title={this.props.title}
				type={type}
				value={(this.props.value?.format?.("YYYY-MM-DDTHH:mm:ss") || this.props.value || "")} />
		);

	}


	/**
	 * Render the `TextField`.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	renderTextField = props => (
		<TextField
			{...props}
			helperText={this.props.helperText}
			InputLabelProps={{...props.InputLabelProps, shrink: this.props.shrink}}
			placeholder={(this.props.placeholder || this.props.label)}
			size={(this.props.size || "small")}
			variant={(this.props.variant || "outlined")} />
	);


	/**
	 * Get the format string.
	 * 
	 * @return {String|undefined}
	 */
	get format() {
		if (this.props.format) {
			return this.props.format;
		}
		else if (this.props.dateOnly) {
			return "DD/MM/YYYY";
		}
		else if (this.props.timeOnly) {
			return "HH:mm";
		}
		else return undefined;
	}


	/**
	 * Get prop forwards.
	 *
	 * @return {Object}
	 */
	get forwards() {
		const forwards = {...this.props};
		delete forwards.dateOnly;
		delete forwards.pickerVariant;
		delete forwards.shrink;
		return forwards;
	}


	/**
	 * `DialogProps`
	 * 
	 * @type {Objectg}
	 */
	static DialogProps = {
		disableScrollLock: true,
		PaperProps: {elevation: 0},
		transitionDuration: 0
	};

}

export default withTheme(DateTimePicker);
